































































































































































































































































.form_items_wrapper {
}
::v-deep .card label {
  text-transform: capitalize;
  margin-bottom: 0;
}
::v-deep .el-form {
  // background: #eee;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
  // align-self: auto;
  // background: #eee;
}
::v-deep .el-form-item {
  // background: #ccc;
  flex-grow: 0.3;
  // margin-bottom: 5px;
  max-width: 247.53px;
  // margin: auto;
}
::v-deep .el-form-item:last-of-type {
  margin-left: 0;
}
::v-deep .el-form-item__content {
  // background: #ccc;
  padding: 0;
}
